import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { graphql } from 'gatsby';
import { Heading5, Paragraph } from '@entur/typography';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import PageHeader from '~/components/PageHeader';
import { ImageDisplay } from '~/components/ImageDisplay';
import BaseCard from '~/components/BaseCardDesignEntur';
export const query = graphql`
query NewLogoFiles {
  downloads: allFile(filter: {sourceInstanceName: {eq: "downloads"}, name: {glob: "*logo*"}}) {
    files: nodes {
      extension
      dir
      name
      publicURL
    }
  }
  logosPng: allFile(filter: {sourceInstanceName: {eq: "media"}, relativeDirectory: {glob: "images/logo"}, extension: {eq: "png"}}) {
    images: nodes {
      name
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  logosSvg: allFile(filter: {sourceInstanceName: {eq: "media"}, relativeDirectory: {glob: "images/logo"}, extension: {eq: "svg"}}) {
    images: nodes {
      name
      publicURL
    }
  }
}
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <PageHeader mdxType="PageHeader" />
    <p>{`Logoen er vår signatur, og skal være tilstede på alle uttak. Vi har utarbeidet en rekke retningslinjer for bruk av logo, og endel eksempler på bruk som skal unngås. Det er viktig at du følger disse, så vi oppnår et konsekvent uttrykk.`}</p>
    <p>{`Vi har laget ulike logopakker som passer til alle formål, og som kan lastes ned her på siden.
RGB er til digitalt bruk, og CMYK til trykk.`}</p>
    <h2 {...{
      "id": "entur-primærlogo"
    }}>{`Entur primærlogo`}</h2>
    <p>{`Dette er vår hovedlogo og skal brukes i de aller fleste sammenhenger. Brukes på lyse bakgrunn og rolige bilder.`}</p>
    <ImageDisplay src={props.data.logosSvg.images.find(image => image.name === "PrimaryLogo").publicURL} downloadSources={[{
      src: props.data.downloads.files.find(file => file.name === "Entur_logo_RGB").publicURL,
      format: "RGB",
      label: "Last ned logopakke i RGB-farger"
    }, {
      src: props.data.downloads.files.find(file => file.name === "Entur_logo_CMYK").publicURL,
      format: "CMYK",
      label: "Last ned logopakke i CMYK-farger"
    }]} name={props.data.logosSvg.images.find(image => image.name === "PrimaryLogo").name} preset="logo-display" alt="Entur sin primærlogo består av teksten Entur med skrifttype Nationale og den blå hovedfargen til Entur. «En» er understreket av en Entur-rød, også kalt koral, strek" mdxType="ImageDisplay" />
    <h2 {...{
      "id": "entur-sekundærlogo"
    }}>{`Entur sekundærlogo`}</h2>
    <p>{`Skal kun tas i bruk når primærlogo ikke er mulig å bruke. Brukes på mørke bakgrunner og bilder med rolige, mørke toner.`}</p>
    <ImageDisplay src={props.data.logosSvg.images.find(image => image.name === "SecondaryLogo").publicURL} name={props.data.logosSvg.images.find(image => image.name === "SecondaryLogo").name} downloadSources={[{
      src: props.data.downloads.files.find(file => file.name === "Entur_logo_RGB").publicURL,
      format: "RGB",
      label: "Last ned logopakke i RGB-farger"
    }, {
      src: props.data.downloads.files.find(file => file.name === "Entur_logo_CMYK").publicURL,
      format: "CMYK",
      label: "Last ned logopakke i CMYK-farger"
    }]} preset="logo-display" className="eds-contrast" alt="Entur sin sekundærlogo består av teksten Entur med skrifttype Nationale og hvit farge. «En» er understreket av en Entur-rød, også kalt koral, strek" mdxType="ImageDisplay" />
    <h2 {...{
      "id": "svart-hvitt-logo"
    }}>{`Svart-hvitt logo`}</h2>
    <p>{`Logo i sort eller hvit skal unngås såvidt mulig, og brukes kun som unntak, i sort/hvite uttak. Hvis nødvendig settes logoen i fargen som gir best kontrast mot bakgrunnen.`}</p>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard fluidSource={props.data.logosPng.images.find(image => image.name === "Sortoghvit_Positiv").childImageSharp.fluid} alt="Entur logo med svart tekst på hvit bakgrunn" variant="none" noPadding mdxType="DoDontCard">
  </DoDontCard>
  <DoDontCard fluidSource={props.data.logosPng.images.find(image => image.name === "Sortoghvit_Negativ").childImageSharp.fluid} alt="Entur logo med hvit tekst på svart bakgrunn" variant="none" noPadding mdxType="DoDontCard">
  </DoDontCard>
    </DoDontGroup>
    <h2 {...{
      "id": "frisone-og-minimum-størrelse"
    }}>{`Frisone og minimum størrelse`}</h2>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard fluidSource={props.data.logosPng.images.find(image => image.name === "Logo_frisone").childImageSharp.fluid} alt="Entur-logo med frisone tilsvarende fontstørrelsen" variant="none" noPadding title="Frisone rundt logo" mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">
      For at lesbarheten til logoen skal være tydelig er det viktig å respektere
      frisonen, så logoen ikke blir forstyrret. Unngå å plassere tekst, bilder
      eller annet i dette området. Plasseres logoen i et hjørne av et format, er
      dette absolutte minste avstand den bør ha til formatets kanter.
    </Paragraph>
  </DoDontCard>
  <DoDontCard fluidSource={props.data.logosPng.images.find(image => image.name === "Logo_minimumstr").childImageSharp.fluid} alt="Entur-logo med minimum størrelse på 20 piksler" variant="none" noPadding title="Minimum størrelse" mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">
      Når logoen skal representeres i små størrelser er det viktig at høyden
      ikke blir lavere enn 20 px på digitalt eller 6 mm på trykk.
    </Paragraph>
  </DoDontCard>
    </DoDontGroup>
    <h2 {...{
      "id": "feil-bruk-av-logo"
    }}>{`Feil bruk av logo`}</h2>
    <p>{`Endre, forvrenge eller behandle logoen galt kan føre til uklar kommunikasjon. Aldri forsøk å lage logoen selv, i en annen font, eller endre størrelsen eller proporsjoner. Under er et par eksempler på hva du skal unngå.`}</p>
    <p><strong parentName="p">{`NB! Bruk kun godkjente filer av logoen. Logopakken kan lastes ned på toppen av denne siden.`}</strong></p>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard title="Proporsjoner" variant="negative" fluidSource={props.data.logosPng.images.find(image => image.name === "Proposjoner").childImageSharp.fluid} alt="Entur-logo som er strukket i vertikalen" mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">
      Logoen skal aldri dras ut av de opprinnelige proporsjonene.
    </Paragraph>
  </DoDontCard>
  <DoDontCard title="Effekter" variant="negative" fluidSource={props.data.logosPng.images.find(image => image.name === "Effekter").childImageSharp.fluid} alt="Entur-logo med pålagt skyggeeffekt" mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">Legg aldri en effekt på logoen.</Paragraph>
  </DoDontCard>
  <DoDontCard title="Skifte farge" variant="negative" fluidSource={props.data.logosPng.images.find(image => image.name === "Skiftefarge").childImageSharp.fluid} alt="Entur-logo i en blåfarge som ikke finnes i fargepaletten" mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">
      Logoen skal ikke brukes i andre farger enn det som er oppgitt.
    </Paragraph>
  </DoDontCard>
  <DoDontCard title="Rotéring" variant="negative" fluidSource={props.data.logosPng.images.find(image => image.name === "Ikkeroter").childImageSharp.fluid} alt="Entur-logo som er rotert cirka 30 grader" mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">Roter, skru eller vend aldri på logoen.</Paragraph>
  </DoDontCard>
  <DoDontCard title="Endre oppsett" variant="negative" fluidSource={props.data.logosPng.images.find(image => image.name === "Endreoppsett").childImageSharp.fluid} alt="Entur-logo hvor En og Tur er stablet i vertikalen" mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">Flytt aldri på bokstavene eller streken i logoen.</Paragraph>
  </DoDontCard>
  <DoDontCard title="Logo på bakgrunn" variant="negative" fluidSource={props.data.logosPng.images.find(image => image.name === "Logobakgrunn").childImageSharp.fluid} alt="Hvit Entur-logo på et lyst bilde, som gjør logoen nesten usynlig" noPadding mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">
      Sett aldri logoen på en bakgrunn som ikke gir nok kontrast.
    </Paragraph>
  </DoDontCard>
    </DoDontGroup>
    <h2 {...{
      "id": "app-ikon"
    }}>{`App-ikon`}</h2>
    <p>{`Denne logo-varianten skal kun benyttes som app-ikon for iOS og Android og som `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Favicon"
      }}>{`favicon`}</a>{` på nettsider.`}</p>
    <ImageDisplay fluidSource={props.data.logosPng.images.find(image => image.name === "App_ikon").childImageSharp.fluid} name={props.data.logosPng.images.find(image => image.name === "App_ikon").name} alt="Entur logo i app-ikon-variant. Består av teksten «EN» i hvit understreket av en Entur-rød, også kalt koral, strek på en Entur-blå avrundet kvadratisk bakgrunn." downloadSources={[{
      src: props.data.downloads.files.find(file => file.name === "EN-app-logo-512x512").publicURL,
      format: "png"
    }]} preset="logo-display" mdxType="ImageDisplay" />
    <h3 {...{
      "id": "rettningslinjer"
    }}>{`Rettningslinjer`}</h3>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard title="Frisone rundt app-ikon" variant="none" fluidSource={props.data.logosPng.images.find(image => image.name === "App_ikon_frisone").childImageSharp.fluid} alt="Entur-appen sitt ikon, med frisonen rundt tilsvarende fontstørrelsen." noPadding mdxType="DoDontCard">
  <Paragraph mdxType="Paragraph">
    Plasseres ikonet i et hjørne av et format, er dette absolutte minste avstand
    den bør ha til formatets kanter.
  </Paragraph>
  </DoDontCard>
    </DoDontGroup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      